import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import './i18n'
import { setDefaults } from 'react-geocode'
import { PostHogProvider } from 'posthog-js/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { setupStore } from './reducers'
import './scss/index.scss'
import { initTracking, posthog } from './utils/posthog'
import { INTERCOM_APP_ID } from './constants'

// Init posthog tracking
initTracking()

setDefaults({
  key: process.env.REACT_APP_GM_KEY,
  region: 'us',
})

console.log('v1.8.1')
axios.interceptors.request.use(
  (config) => {
    const updatedConfig = { ...config }
    const token = localStorage.getItem('token')
    return {
      ...updatedConfig,
      headers: {
        ...updatedConfig.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)
const store = setupStore()
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <BrowserRouter>
          <Helmet>
            <script>
              {`
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              `}
            </script>
          </Helmet>
          <App />
        </BrowserRouter>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
