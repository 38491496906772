import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { FilloutStandardEmbed } from '@fillout/react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDynamicField } from '../../reducers/claim'
import { selectClaimTypeLossObjects } from '../../selectors/claim'
import { selectUserDeviceById } from '../../selectors/devices'
import { propTypes, defaultProps } from './props'
import Spinner from '../spinner'
import '@fillout/react/style.css'

const FORM_TYPE = {
  'smartwatch': 'Watch',
  'phone': 'Phone',
}

export function FilloutForm({
  className,
  prefill,
  label,
  instruction,
  taskGroupName,
  id: fieldId,
  onChange,
  ...props
}) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const lossTypes = useSelector(selectClaimTypeLossObjects)
  const deviceDetail = useSelector((state) =>
    selectUserDeviceById(state, props.deviceId)
  )

  const onSubmit = () => {
    setIsLoading(true)
    onChange({ fieldId, newState: 'DONE' })

    const fieldData = {
      claim_id: props.claimId,
      claim_device_id: props.claimDeviceId,
      device_id: props.deviceId,
      field_id: fieldId,
      handler: 'checkbox',
      checked: true,
    }
    dispatch(updateDynamicField(fieldData))
      .unwrap()
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  if (isLoading) {
    return <Spinner />
  }
  if (props.field_state === 'DONE') {
    return <div className={c('text p-1 mb-3', className)}>
      <p>Thank you for submitting the form. Please proceed by clicking on the button below.</p>
    </div>
  }
  return (
    <div className={c('text p-1 mb-3', className)}>
      <FilloutStandardEmbed
        filloutId="2QnTtyJBovus"
        dynamicResize
        domain="forms.getakko.com"
        parameters={{
          dropdown: FORM_TYPE[deviceDetail.kind],
          claimId: props.claimId,
          claimDeviceId: props.claimDeviceId,
          deviceId: props.deviceId,
          dateRequested: new Date().toISOString(),
          imei: deviceDetail.imei,
          make: deviceDetail.item_make,
          makeId: deviceDetail.item_make_id,
          model: deviceDetail.model,
          storage: deviceDetail.storage,
          lossTypes: lossTypes[props.claimDeviceId].map((loss) => `${loss.cat} - ${loss.lt}`),
        }}
        onSubmit={() => {
          onSubmit()
        }}
      />
    </div>
  )
}
export default FilloutForm

FilloutForm.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

FilloutForm.defaultProps = {
  ...defaultProps,
  className: '',
}
