import posthog from 'posthog-js'

export const initTracking = function initTracking() {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
  })
}

export const trackEvent = function trackEvent(event, payload) {
  posthog.capture(event, payload)
}

export const identifyUser = function identifyUser(userId) {
  posthog.identify(`user_${userId}`, { usedClaimsSite: true })
}

export const reset = function reset() {
  posthog.reset()
}

export { posthog }
