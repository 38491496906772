import React from 'react'
import Heading from './heading'
import SubHeading from './sub-heading'
import Paragraph from './paragraph'
import Resource from './resource'
import InputText from './input-text'
import Text from './text'
import TextClear from './text-clear'
import Hyperlink from './hyperlink'
import RepairShop from './repair-shop'
import PartnerRepairShop from './partner-repair-shop'
import Repairable from './repairable'
import MailedDevice from './mailed-device'
import Button from '../button'
import RichText from './rich-text'
import NumericText from './numeric-text'
import RepairCost from './repair-cost'
import RepairReplace from './repair-replace'
import Selector from './selector'
import MultiSelect from './multi-select'
import Radio from './radio'
import Checkbox from './checkbox'
import StoreAddress from './store-address'
import ShipmentAddress from './shipment-address'
import Images from './images'
import Evidences from './evidences'
import Videos from './videos'
import Files from './files-v2'
import Payment from './payment'
import Rate from './rate'
import Feedback from './feedback'
import Verification from './verification'
import ShippingLabel from './shipping-label'
import ShopStores from './shop-stores'
import AutoRefresh from './auto-refresh'
import PaymentServiceFee from './payment-service-fee'
import FilloutForm from './fillout-form'

export const FieldsFactory = {
  // Info
  TEXT: (props) => <Text {...props} />,
  CLEAR: (props) => <TextClear {...props} />,
  HYPERLINK: (props) => <Hyperlink {...props} />,
  REPAIR_SHOP: (props) => <RepairShop {...props} />,
  PARTNER_REPAIR_SHOP: (props) => <PartnerRepairShop {...props} />,
  SHIPPING_LABEL: (props) => <ShippingLabel {...props} />,
  SHOP_STORE: (props) => <ShopStores {...props} />,
  AUTO_REFRESH: (props) => <AutoRefresh {...props} />,

  // on hold
  HEADING: (props) => <Heading {...props} />,
  SUB_HEADING: (props) => <SubHeading {...props} />,
  PARAGRAPH: (props) => <Paragraph {...props} />,
  RESOURCE: (props) => <Resource {...props} />,
  RICH_TEXT: (props) => <RichText {...props} />,

  // Fillable
  REIMBURSEMENT: (props) => <Payment {...props} />,
  FILES: (props) => <Files {...props} />,
  FILES_ESTIMATES: (props) => <Files {...props} />,
  FILES_RECEIPTS: (props) => <Files {...props} />,
  REPAIR_COST: (props) => <RepairCost {...props} />,
  REPAIR_COST_ESTIMATE: (props) => <RepairCost {...props} />,
  REPAIR_COST_FINAL: (props) => <RepairCost {...props} />,
  REPAIR_REPLACE: (props) => <RepairReplace {...props} />,
  RATE: (props) => <Rate {...props} />,
  FEEDBACK: (props) => <Feedback {...props} />,
  VERIFICATION: (props) => <Verification {...props} />,
  REPAIRABLE: (props) => <Repairable {...props} />,
  MAILED_DEVICE: (props) => <MailedDevice {...props} />,
  IMAGES: (props) => <Images {...props} />,
  EVIDENCES: (props) => <Evidences {...props} />,
  CHECKBOX: (props) => <Checkbox {...props} />,
  SHOP_ADDRESS: (props) => <StoreAddress {...props} />,
  SHIPMENT_ADDRESS: (props) => <ShipmentAddress {...props} />,
  PAYMENT_SERVICE_FEE: (props) => <PaymentServiceFee {...props} />,
  FILLOUT_FORM: (props) => <FilloutForm {...props} />,

  // on hold
  STRING: (props) => <InputText {...props} />,
  NUMBER: (props) => <NumericText {...props} />,
  SELECT: (props) => <Selector {...props} />,
  MULTI_SELECT: (props) => <MultiSelect {...props} />,
  RADIO: (props) => <Radio {...props} />,
  SUBMIT: (props) => <Button {...props} />,
  IMAGE: (props) => <div>image field</div>,
  VIDEO: (props) => <div>video field</div>,
  VIDEOS: (props) => <Videos {...props} />,
  RECEIP: (props) => <div>receip field</div>,
  // FILE: (props) => <Files {...props} />,
}
