import React, { useEffect, useState } from 'react'
import c from 'classnames'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { IconCheckWhite } from '../icons/icon-check-white'
import Spinner from '../spinner'
import { propTypes, defaultProps } from './props'
import { updateDynamicField } from '../../reducers/claim'
import PaymentForm from '../payment-form'
import { selectClaimItemAffectedByID } from '../../selectors/claim'
import { getPaymentServiceFee } from '../../reducers/claim/thunks'

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export function PaymentServiceFee({
  className,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  label,
  prefill,
  instruction,
  onChange,
  field_state: fieldState,
  ...props
}) {
  const [paying, setPaying] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const dispatch = useDispatch()
  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )
  const paymentIsAlreadyDone =
    device.paymentServiceFee && device.paymentServiceFee.status === 'SUCCESS'
  const paymentAmount = paymentIsAlreadyDone
    ? device.paymentServiceFee.amount
    : null
  const [completed, setCompleted] = useState(paymentIsAlreadyDone)
  const [loading, setLoading] = useState(!paymentIsAlreadyDone)
  const [paymentServiceFeeAmount, setPaymentServiceFeeAmount] =
    useState(paymentAmount)

  const onStartTransaction = () => {
    setFailed(false)
  }

  const onFailStripeToken = () => {
    setFailed(true)
  }

  const onSubmitPayment = (values) => {
    setPaying(true)
    setFailed(false)
    const fieldData = {
      claim_id: claimId,
      claim_device_id: claimDeviceId,
      device_id: deviceId,
      field_id: fieldId,
      handler: 'payment_service_fee',
      token: values.token.id,
      amount: paymentServiceFeeAmount,
    }
    dispatch(updateDynamicField(fieldData))
      .unwrap()
      .then((res) => {
        setPaying(false)
        if (res.response.payment_transaction_status === 'SUCCESS') {
          setSuccess(true)
          setCompleted(true)
        }
        if (res.response.payment_transaction_status === 'FAILED') {
          setFailed(true)
        }
      })
      .catch(() => {
        setPaying(false)
        setFailed(true)
      })
  }

  let paymentDate = new Date()
  if (device.paymentServiceFee) {
    paymentDate = new Date(device.paymentServiceFee.date)
  }

  useEffect(() => {
    if (completed !== true) {
      dispatch(getPaymentServiceFee({ claimId, claimDeviceId }))
        .unwrap()
        .then((res) => {
          setLoading(false)
          setPaymentServiceFeeAmount(res)
        })
    }
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className={c('payment-service-fee', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      <div className="payment-service-fee_summary">
        <div className="payment-service-fee_summary-label">
          Service Payment Fee:
        </div>
        <div className="payment-service-fee_summary-amount">
          USD&nbsp;
          {paymentServiceFeeAmount}
        </div>
      </div>
      {!completed && (
        <Elements stripe={promise}>
          <PaymentForm
            onSubmitPayment={onSubmitPayment}
            onStartTransaction={onStartTransaction}
            onFailStripeToken={onFailStripeToken}
            loading={paying}
          />
        </Elements>
      )}
      {success || completed ? (
        <div className={c('payment-confirmation', className)}>
          <div className="message">
            <div className="check">
              <IconCheckWhite />
            </div>
            <div className="detail">
              <div className="detail_txt">Payment successful</div>
              <div className="detail_date">
                {moment(paymentDate).format('DD MMM YYYY')}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {failed && (
        <div className={c('error-box2', className)}>
          Payment failed, please try again
        </div>
      )}
    </div>
  )
}
export default PaymentServiceFee

PaymentServiceFee.propTypes = {
  ...propTypes,
}

PaymentServiceFee.defaultProps = {
  ...defaultProps,
}
