import { createSelector } from '@reduxjs/toolkit'
import {
  ALLOW,
  PARTNER_REPAIRS_REPAIR_FIRST,
  PARTNER_REPAIRS_REGULAR_REPAIR,
} from '../constants/permissions'

export const partnerStore = (state) => state.partner

export const selectPartnerPermissions = createSelector(
  partnerStore,
  (partner) => partner.partnerPermissions
)

export const selectPartnerBranding = createSelector(
  partnerStore,
  (partner) => partner.theme
)

export const selectPartnerPermissionByScope = createSelector(
  [selectPartnerPermissions, (_, scope) => scope],
  (partnerPermissions, scope) =>
    partnerPermissions.find((p) => p.scope === scope)
)

export const selectPartnerPermissionValueByScope = createSelector(
  [selectPartnerPermissions, (_, scope) => scope],
  (partnerPermissions, scope) => {
    const per = partnerPermissions.find((p) => p.scope === scope)
    if (per) return per.value
    return null
  }
)

export const selectPartnerPropertyRepairInfo = createSelector(
  partnerStore,
  (partner) => {
    const repairFirstShop = partner.partnerPermissions.find(
      (p) => p.scope === PARTNER_REPAIRS_REPAIR_FIRST
    )
    const regularRepairShop = partner.partnerPermissions.find(
      (p) => p.scope === PARTNER_REPAIRS_REGULAR_REPAIR
    )
    const isRepairFirstShop =
      (repairFirstShop ? repairFirstShop.value : null) === ALLOW
    const isRegularRepairShop =
      (regularRepairShop ? regularRepairShop.value : null) === ALLOW
    return {
      name: partner.partnerPropertyName,
      address: partner.partnerPropertyAddress,
      isRepair: isRepairFirstShop || isRegularRepairShop,
      repairDevices: partner.repairDevices,
    }
  }
)

export const selectPartnerId = createSelector(
  partnerStore,
  (partner) => partner.partnerId
)
